<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Sie können von unterschiedlichen Layouts wählen. Dadurch verändern sich bspw. Design oder Anrede-Form.',
    },
  },
};
</script>
