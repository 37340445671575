<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Wählen Sie das angefertigte Unternehmensprofil aus, das Sie in dem Anschreiben anhängen möchten.',
    },
  },
};
</script>
