<script>
import Vue from 'vue';
import Profile from '~/models/Profile';
import ResourceSelect from '~/components/ResourceSelect/ResourceSelect.vue';

export default Vue.extend({
  extends: ResourceSelect,

  props: {
    getOptionLabel: {
      type: Function,
      required: false,
      default: resource => resource?.name,
    },

    query: {
      type: Object,
      required: false,
      default: () => new Profile(),
    },
  },

  methods: {
    fetch() {
      return this.query.$all();
    },
  },
});
</script>
