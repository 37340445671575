<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Geben Sie dem Anschreiben einen eindeutigen Namen, wie z.B. "Bewerber:innen Chemikanten".',
    },
  },
};
</script>
