<template>
  <div class="template-renderer" :class="{ 'template-renderer--unready': !ready }">
    <iframe
      v-show="ready"
      ref="preview"
      class="template-renderer__preview"
      @load="iframeReady = true"
    />
    <EmptyState
      v-if="!ready"
      image="employee-dashboard-empty"
      :title="$t('pages.email_template.empty')"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Twig from 'twig';
import { merge } from 'lodash-es';
import EmptyState from '~/components/EmptyState';

export default Vue.extend({
  components: {
    EmptyState,
  },

  props: {
    layout: {
      type: Object,
      required: false,
      default: null,
    },

    profile: {
      type: Object,
      required: false,
      default: null,
    },

    definitions: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    iframeReady: false,
    template: null,
  }),

  computed: {
    ready() {
      return !!(this.layout && this.profile && this.template && this.iframeReady);
    },
  },

  watch: {
    ready: {
      immediate: true,
      handler() {
        this.render();
      },
    },

    layout: {
      immediate: true,
      handler(value) {
        if (value) {
          this.template = Twig.twig({ data: value.template });
        }
      },
    },

    definitions: {
      deep: true,
      immediate: true,
      handler() {
        this.render();
      },
    },
  },

  methods: {
    render() {
      if (!this.ready) {
        return;
      }

      const profile = merge({}, this.profile, {
        meta: { color: this.profile?.meta?.color || '#ff2d21' },
      });

      const preview = this.template.render({
        profile,
        definitions: this.definitions,
        user: null,
      });

      this.$refs.preview.contentWindow.document.body.innerHTML = preview;
    },
  },
});
</script>

<style lang="scss" scoped>
.template-renderer {
  width: 100%;
  height: 100%;

  &--unready {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__preview {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }
}
</style>
