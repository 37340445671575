<template>
  <TLoading v-if="$fetchState.pending" />

  <t-body v-else>
    <t-sidebar class="t-sidebar--wide">
      <h3>
        {{
          creating
            ? $t('pages.email_template.actions.create')
            : $t('pages.email_template.actions.edit')
        }}
      </h3>
      <span>{{ $t('pages.email_template.salutation_hint') }}</span>
      <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(save)">
          <t-grid>
            <t-grid-row>
              <section class="view-templates-helper">
                <span>{{ $t('pages.email_template.need_help') }}</span>
                <a :href="emailTemplateHelpUrl" target="_blank">
                  <t-button type="simple">{{
                    $t('pages.email_template.actions.view_templates')
                  }}</t-button>
                </a>
              </section>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="label"
                  :name="$t('fields.internal_label')"
                  rules="required|min:3|max:255"
                >
                  <EmailTemplateLabelHelp slot="help" />
                  <t-input
                    v-model="form.label"
                    native-type="text"
                    :placeholder="$t('fields.internal_label_placeholder')"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="organization_profile_id"
                  :name="$t('fields.profile')"
                  rules="required|string"
                >
                  <EmailTemplateProfileHelp slot="help" />
                  <ProfileSelect
                    v-model="form.organization_profile_id"
                    @original="profile = $event"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell>
                <t-validation-wrapper
                  vid="email_layout_id"
                  :name="$t('fields.layout')"
                  rules="required|string"
                >
                  <EmailTemplateLayoutHelp slot="help" />
                  <EmailLayoutSelect v-model="form.email_layout_id" @original="layout = $event" />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <t-grid-cell
                v-for="declaration in declarations"
                :key="declaration.name"
                :size="declaration.width"
              >
                <t-validation-wrapper
                  :vid="`declarations.${declaration.reference}`"
                  :name="declaration.label"
                  :rules="declaration.rules.join('|')"
                >
                  <THelp slot="help" :label="declaration.help" />
                  <component
                    :is="getComponentForType(declaration.type)"
                    v-model="form.definitions[declaration.reference]"
                    v-bind="declaration.attributes"
                  />
                </t-validation-wrapper>
              </t-grid-cell>
            </t-grid-row>

            <t-grid-row>
              <div
                style="
                  flex-direction: row-reverse;
                  display: flex;
                  width: 100%;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <t-button-loading :disabled="invalid" :loading="loading" native-type="submit">
                  {{ $t('global.actions.save') }}
                </t-button-loading>

                <a v-if="!creating" href="." @click.prevent="deleteConfirmationVisible = true">{{
                  $t('global.actions.delete')
                }}</a>
              </div>
            </t-grid-row>
          </t-grid>
        </t-form>
      </validation-observer>
    </t-sidebar>

    <t-page inline style="overflow: hidden">
      <TemplateRenderer :layout="layout" :profile="profile" :definitions="form.definitions" />
    </t-page>

    <portal to="modals">
      <t-confirm
        v-if="deleteConfirmationVisible"
        :button-label="$t('global.actions.confirm_deletion')"
        @confirmed="destroy"
        @cancelled="deleteConfirmationVisible = false"
      >
        <template #header>
          {{ $t('global.confirmation.delete') }}
        </template>
        <p>{{ $t('global.confirmation.final_action') }}</p>
      </t-confirm>
    </portal>
  </t-body>
</template>

<script>
import Vue from 'vue';
import EmailTemplate from '~/models/EmailTemplate';
import EmailLayoutSelect from '~/components/ResourceSelect/EmailLayoutSelect';
import ProfileSelect from '~/components/ResourceSelect/ProfileSelect';
import TLoading from '~/components/TLoading';
import TValidationWrapper from '~/components/TValidationWrapper';
import TemplateRenderer from '~/pages/shared/email-templates/partials/TemplateRenderer';
import THelp from '~/components/THelp';
import EmailTemplateLabelHelp from '~/components/Help/EmailTemplateLabelHelp';
import EmailTemplateProfileHelp from '~/components/Help/EmailTemplateProfileHelp';
import EmailTemplateLayoutHelp from '~/components/Help/EmailTemplateLayoutHelp';
import UserTypeEnum from '~/enums/UserTypeEnum';

export default Vue.extend({

  components: {
    TLoading,
    EmailLayoutSelect,
    ProfileSelect,
    TValidationWrapper,
    TemplateRenderer,
    THelp,
    EmailTemplateLabelHelp,
    EmailTemplateProfileHelp,
    EmailTemplateLayoutHelp,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      template: null,
      layout: null,
      profile: null,
      form: null,
      loading: false,

      deleteConfirmationVisible: false,
    };
  },

  async fetch() {
    if (this.$route.params?.email_template_id) {
      this.template = await EmailTemplate.$find(this.$route.params.email_template_id);
    }

    this.form = this.getForm(this.template);
  },

  computed: {
    creating() {
      return !(this.template && this.template.id);
    },

    declarations() {
      return this.layout ? this.layout.declarations : [];
    },

    tenantSlug() {
      return this.$store.state.tenant.tenant.slug;
    },

    emailTemplateHelpUrl() {
      return `https://ausbildungsradar.de/${this.tenantSlug}/bewerber-kontakt`;
    },
  },

  methods: {
    getForm(template = null) {
      return {
        label: template?.label,
        definitions: template?.definitions ?? {},
        email_layout_id: template?.email_layout_id,
        organization_id: this.organizationId,
        organization_profile_id: template?.organization_profile_id,
      };
    },

    getPayload() {
      return {
        ...this.form,
      };
    },

    getComponentForType(type) {
      switch (type) {
        case 'textarea':
          return 't-textarea';
        case 'input':
          return 't-input';
      }
    },

    rerouteToIndex() {
      const destinationRoute = [UserTypeEnum.ADMIN, UserTypeEnum.TENANT_MEMBER].includes(
        this.$auth.me.user.type,
      )
        ? {
            name: this.$RouteEnum.ORGANIZATION.EMAIL_TEMPLATES,
            params: { id: this.organizationId },
          }
        : { name: this.$RouteEnum.EMAIL_TEMPLATES.INDEX };
      return this.$router.push(destinationRoute);
    },

    async save() {
      if (this.creating) {
        return await this.create(this.getPayload());
      }

      return await this.update(this.getPayload());
    },

    async create(payload) {
      this.loading = true;
      try {
        await new EmailTemplate({
          ...payload,
        }).save();

        await this.$notify.success(this.$t('notifications.email_template.created'));
        await this.rerouteToIndex();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async update(payload) {
      this.loading = true;
      try {
        await new EmailTemplate({
          id: this.template.id,
          ...payload,
        }).patch();

        await this.$notify.success(this.$t('notifications.email_template.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async destroy() {
      try {
        await new EmailTemplate({
          id: this.template.id,
        }).delete();

        await this.$notify.success(this.$t('notifications.email_template.deleted'));
        await this.rerouteToIndex();

        this.deleteConfirmationVisible = false;
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.t-sidebar--wide {
  width: 42rem;
}
.view-templates-helper {
  margin-top: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>
